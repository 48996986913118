import React from 'react';
// import { Link } from 'gatsby'

import Layout from '../components/layout/Layout';
import CardHeading from '../components/layout/CardHeading';

const heading = {
  title: 'Session Expired',
  desc: "Oops you're session expired please login again!",
  btnName: 'Ok',
  btnPath: '/',
};

const NotFoundPage = () => (
  <Layout data-test="session-expired-component">
    <div className="warning-message">
      <CardHeading heading={heading} />
    </div>
  </Layout>
);

export default NotFoundPage;
